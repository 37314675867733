<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>分店管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-xl-8 d-flex items-center">
          <h4 class="font-weight-bold">分店管理</h4>
        </div>
        <div class="col-12 col-xl-4">
          <b-input-group>
            <b-form-input placeholder="搜尋分店編號或店名" v-model="search" @keyup.enter="handleSearch"></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="d-flex flex-column flex-xl-row mt-4 mb-3 justify-content-between">
        <b-breadcrumb class="mb-2 p-0" :items="breadcrumb"></b-breadcrumb>
        <div v-if="showCreateButton">
          <b-button variant="primary" :disabled="activeBranch && activeBranch.branch_type === 'shop'"
            @click="handleUpload">
            <i class="fa fa-plus"></i>
            {{ $route.name === "Branches" ? '上傳最上層分店' : '上傳分店' }}
          </b-button>
          <b-button class="ml-4" variant="primary" :disabled="activeBranch && activeBranch.branch_type === 'shop'"
            @click="handleCreate">
            <i class="fa fa-plus"></i>
            {{ $route.name === "Branches" ? '新增最上層分店' : '新增分店' }}
          </b-button>
        </div>
      </div>

      <b-table striped hover responsive :items="branches" :fields="fields" :busy="isLoading">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(branch_code)="data">
          <router-link :to="{ name: 'BranchesShow', params: { branchID: data.item.id } }">{{ data.item.branch_code
            }}</router-link>
        </template>

        <template #cell(actions)="data">
          <b-button v-if="$permissions.has($permissions.consts.BRANCH_MODIFY)" variant="inverse-warning" :to="{
              name: 'BranchesEdit',
              params: {
                branchID: data.item.id,
              },
            }">
            編輯
          </b-button>
          <b-button class="ml-2" v-if="$permissions.has($permissions.consts.BRANCH_MODIFY)" variant="inverse-success"
            :to="{
              name: 'BranchUpdateImage',
              params: {
                branchID: data.item.id,
              },
            }">
            設定圖片
          </b-button>
          <b-button v-if="$permissions.hasAny([
              $permissions.consts.BRANCH_DETAIL_MODIFY_STAFF,
              $permissions.consts.BRANCH_DETAIL_DELETE_STAFF,
              $permissions.consts.BRANCH_DETAIL_CHANGE_PASSWORD
            ])" class="ml-2" variant="primary" :to="{
              name: 'BranchStaffs',
              params: {
                branchID: data.item.id,
              },
            }">店內帳號</b-button>

          <b-button class="ml-2" variant="inverse-info" v-if="!activeBranch || (activeBranch && activeBranch.level < 2)"
            :to="{
              name: 'BranchesSubLayer',
              params: {
                branchID: data.item.id,
              },
            }"
            :disabled="(activeBranch && (activeBranch.branch_type === 'shop')) || data.item.branch_type === 'shop'">下層</b-button>
          <b-button v-if="$permissions.has($permissions.consts.BRANCH_DELETE)" class="ml-2" variant="inverse-danger"
            @click="
              handleDelete(data.item.id, data.item.branch_code, data.item.name)
              ">刪除</b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination class="separated" v-model="currentPage" :total-rows="total" :per-page="perPage"
          aria-controls="groupbuyList" @change="(page) => fetchBranches(page)"></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import branchApi from "../../../apis/branch";
import * as consts from '@/consts'

export default {
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      branches: [],
      activeBranch: null,
      fields: [
        {
          key: "branch_code",
          label: "分店編號",
        },
        {
          key: "name",
          label: "店名",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.activeBranch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }) ?? [];

      const active = {
        text: this.activeBranch?.name,
        href: "#",
        active: true,
      };

      return this.activeBranch ? [root, ...parents, active] : [root, active];
    },
    branchID() {
      return this.$route.params.branchID;
    },
    showCreateButton() {
      if (this.$route.name === 'Branches') {
        return this.$permissions.has(this.$permissions.consts.BRANCH_TOP_CREATE);
      }
      return this.$permissions.has(this.$permissions.consts.BRANCH_MODIFY);
    },
  },
  mounted() {
    this.fetchBranches();

    if (this.branchID) {
      this.fetchActiveBranch();
    }
  },
  watch: {
    branchID(val, oldVal) {
      if (val !== oldVal) {
        this.search = "";
        this.fetchBranches();
        this.activeBranch = null;

        if (this.branchID) {
          this.fetchActiveBranch();
        }
      }
    },
  },
  methods: {
    async fetchBranches(page) {
      try {
        this.isLoading = true;
        // only_my_branch 會拉此會員第一層的分店，有兩個情境下會是 false
        // - 如果已經有明確指定要拉的子店時(this.$route.params.branchID 有值時)
        // - 如果有 BRANCH_TOP_VIEW 顯示最上層的權限，就不會只看會員的第一層，而是要看最上層的分店
        const { data } = await branchApi.getBranches(this.organization, {
          only_my_branch: !this.$route.params.branchID && !this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW),
          parent_id: this.$route.params.branchID || "",
          query: this.search,
          page: page || this.currentPage,
          per_page: 10,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.branches = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    handleSearch() {
      this.fetchBranches();
    },

    changePage() {
      this.fetchBranches();
    },

    async fetchActiveBranch() {
      try {
        this.isLoading = true;
        const { data } = await branchApi.getBranch(this.branchID);

        this.activeBranch = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    async handleDelete(branchID, branchCode, branchName) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>店名：${branchName}</div>
                <div>分店編號：${branchCode}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await branchApi.deleteBranch(branchID);
              await this.fetchBranches();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },

    handleUpload() {
      if (this.activeBranch) {
        this.$router.push({
          name: "BranchesImport",
          params: {
            branchID: this.branchID,
          },
        });
      } else {
        this.$router.push({
          name: "BranchesRootImport",
        });
      }
    },
    handleCreate() {
      if (this.activeBranch) {
        this.$router.push({
          name: "BranchesCreate",
          params: {
            branchID: this.branchID,
          },
        });
      } else {
        this.$router.push({
          name: "BranchesRootCreate",
        });
      }
    },
  },
};
</script>
